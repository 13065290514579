import { values } from 'lodash';
import withSettingsTabsWrapper from '../../modules/settings/components/withSettingsTabsWrapper';

export default {
    name: 'globalMaxInventorySettingsPage',
    before: [],
    after: [],
    setup: (app) => {
        const id = "globalMaxInventorySettings"
        const link = `/settings/global/max-inventory-level`;
        const title = `Max Inventory Level`;
        const permission = 'settings - general.save';
    
        const globalSettingsTab = app.settingsTabs.getItem('global');
        const globalSettingsTabs = values(app.settingsGlobalTabs.getAll());
    
        app.settingsTabs.changeItem('global', {
            ...globalSettingsTab,
            children: [
                ...globalSettingsTabs,
                {
                    id,
                    label: title,
                    title,
                    link,
                    permission,
                    order: 90,
                },
            ],
        });
    
        app.routes.addItem(id, {
            name: id,
            path: link,
            title,
            permission,
            breadcrumbConfig: {
                title,
                isTab: true,
                clearPath: true,
            },
            permission,
            props: () => ({}),
            loader: () => import('./packages/GlobalMaxInventorySettings/core'),
            render: withSettingsTabsWrapper(app),
        });
    },
};

// temporary "custom". With next core release it should be removed
export default {
    name: 'printUpdate',
    before: [],
    after: [],
    setup: (app) => {

        app.routes.addMany({
            pickupPackingSlipPrint: {
                path: `/fulfillment-pickup/packing-slip/:id`,
                title: `Fulfillments Pickup Print`,
                permission: ['fulfillments.show'],
                breadcrumbConfig: {
                    name: `Fulfillments Pickup Print`,
                },
                props: () => ({}),
                loader: () => import('@skunexus/pickup-packing-slip-print'),
            },
            shipmentPrint: {
                path: '/shipment-print/:id',
                title: `Shipment Print`,
                permission: ['fulfillments.show'],
                breadcrumbConfig: {
                    name: `Shipment Print`,
                },
                props: () => ({}),
                loader: () => import('@skunexus/shipment-print'),
            }
        });

        const shipmentName = 'shipment';
        const shipmentRoute = app.routes.getItem(shipmentName);
        if (shipmentRoute) {
            const routeProps = shipmentRoute.props;
            shipmentRoute.props = () => ({
                ...routeProps,
                shipmentPrintPageRoute: '/shipment-print',
            });

            app.routes.changeItem(shipmentName, shipmentRoute);
        }

        const fulfillmentShipDetailsPageName = 'fulfillmentShipDetails';
        const fulfillmentShipDetailsPageRoute = app.routes.getItem(fulfillmentShipDetailsPageName);
        if (fulfillmentShipDetailsPageRoute) {
            const routeProps = fulfillmentShipDetailsPageRoute.props;
            fulfillmentShipDetailsPageRoute.props = () => ({
                ...routeProps(),
                shipmentPrintPageRoute: '/shipment-print',
            });

            app.routes.changeItem(fulfillmentShipDetailsPageName, fulfillmentShipDetailsPageRoute);
        }

        const fulfillmentShipPackingPageName = 'fulfillmentShipPacking';
        const fulfillmentShipPackingPageRoute = app.routes.getItem(fulfillmentShipPackingPageName);
        if (fulfillmentShipPackingPageRoute) {
            const routeProps = fulfillmentShipPackingPageRoute.props;
            fulfillmentShipPackingPageRoute.props = () => ({
                ...routeProps(),
                shipmentPrintPageRoute: '/shipment-print',
            });

            app.routes.changeItem(fulfillmentShipPackingPageName, fulfillmentShipPackingPageRoute);
        }

        const pickupDetailsPageName = 'pickupDetails';
        const pickupDetailsPageRoute = app.routes.getItem(pickupDetailsPageName);
        if (pickupDetailsPageRoute) {
            const routeProps = pickupDetailsPageRoute.props;
            pickupDetailsPageRoute.props = () => ({
                ...routeProps(),
                packingSlipPrintPageRoute: '/fulfillment-pickup/packing-slip',
            });

            app.routes.changeItem(pickupDetailsPageName, pickupDetailsPageRoute);
        }
    },
};
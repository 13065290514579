export default {
    name: 'poFormPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'purchaseOrderForm';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {

            const isDisabledField = ({ state, details = {}, fieldName }) => {
                const { purchaseOrderEditableFields = [], delivery_type } = details;
            
                if (state === 'active' && delivery_type === 1) {
                    return false;
                }
            
                if (fieldName === 'line_items' || fieldName === 'vendor_contacts') {
                    return state === 'active';
                }
            
                return state === 'active' && !purchaseOrderEditableFields.some((field) => field.label === fieldName);
            };

            
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                isDisabledField
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};